import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  // Fetch the user's location
  useEffect(() => {
    setFetchAttempted(true); // Set to true as fetch begins
  
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
  
        if (decodedCity === "unknown") {
          // If city is "unknown", fetch from the external API
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData); // Log the external API data
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          // Use the local API data
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
          setCity(decodedCity);
          console.log(data); // Log the local API data
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
        // Optionally handle the error by fetching from external API or other means
      });
  
  }, []);

  // Countdown timer logic
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Function to format the countdown timer
  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (

            <div className="landing-container">
              {/* Video Background */}
              <video
  autoPlay
  playsInline
  preload="auto"
  loop
  muted
  style={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    objectFit: 'cover',
    zIndex: -1,
  }}
>
<source src="https://ofmpics.com/skyyy.mp4" type="video/mp4" />
Your browser does not support the video tag.
</video>

              {/* Main content */}
              <div className="container">
                <a className="header-logo" href="https://onlyfans.com/itsskyybby/c11">
                  <div className="onlyfans"></div>
                </a>
                <img
                  src="https://i.ibb.co/qB1byf8/Screenshot-2024-05-08-at-13-27-53-modified.png"
                  alt="Header Image"
                  className="header-image"
                />

                <div className="textContainer">
                  <section>
                    <div className="username"><strong> Sky 🦋</strong></div>
                    <div className="useraccountname">@itsskyybby</div>

                    <div id="user-status" className="user-status">
                      <div className="status"></div>
                      <div id="available"><strong>Available now</strong></div>
                      <div className="separator"></div>
                      <div className="clock"></div>
                      <div className="respond-time"><strong>Responds in 2 minutes</strong></div>
                    </div>
                  </section>
                </div>

                <div className="textContainer">
                  <div className="location"></div>
                  <span id="location-name"><strong>{city ? `${city} ${region}` : '<3'}</strong></span>
                </div>

                <div className="textContainer2">
                  <div className="time-in-current-location">
                    <strong>My name is Sky. Flight attendant, Looking for content partners in {city || 'your city'} hoping to do OnlyFans full-time.❤️
</strong>
                  </div>
                </div>

                <div className="textContainer3">
                  <a href="https://onlyfans.com/itsskyybby/c11" id="customButton">
                    <div className="onlyfans"></div>
                    My Onlyfans page 🔞
                  </a>
                </div>

                <div className="countdown-block">
                  <span className="discount"><strong>35% OFF </strong></span>
                  <span className="timer-end"><strong>ends in</strong> </span>
                  <span id="timer"><strong>{formatTimeLeft(timeLeft)}</strong></span>
                </div>

                <Analytics />
              </div>
            </div>

  );
}

export default App;
